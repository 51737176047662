@font-face {
  font-family: "Helvetica-Regular";
  src: url("./assets/fonts/Helvetica-Regular.woff2") format("woff2"),
    url("./assets/fonts/Helvetica-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica-Bold";
  src: url("./assets/fonts/Helvetica-Bold.woff2") format("woff2"),
    url("./assets/fonts/Helvetica-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.btn:focus,
.btn.focus {
  outline: 0;
  /* box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%); */
}
.textclr {
  color: white;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Helvetica-Bold";
}
p,
label,
a,
ul li,
tr th,
tr td,
input,
select {
  font-family: "Helvetica-Regular" !important;
}
input::placeholder {
  font-family: "Helvetica-Regular" !important;
}
.font-bold {
  font-family: "Helvetica-Bold!important";
}
a:hover {
  text-decoration: none;
}
a,
button,
.btn:focus {
  outline: none;
  box-shadow: none;
}
.clr {
  cursor: pointer;
}
.noti-text {
  font-size: 1.563rem;
}
body {
  margin: 0;
  font-family: Helvetica-Regular;
}
.two-two {
  font-size: 1.375rem;
}
.show dropdown {
  display: none;
}
.vh_80 {
  height: calc(100vh - 140px);
}
.bg-grey {
  background-color: #f8f8f8;
}
.underline {
  text-decoration: underline !important;
}
.pop_title {
  color: #125766;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}
.page-title {
  font-family: helvetica;
  font-size: 1.375rem;
  font-weight: bold;
  margin: 0px;
  padding: 10px 0px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

label.form-check-label {
  font-weight: normal;
}

body {
  background-image: url("./assets/images/Group1324.jpg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.bg_strike {
  background-image: url("./assets/images/imgpsh_fullsize_anim.jpg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.btn_sol {
  background-image: url("./assets/images/Grou.svg") !important;
  background-repeat: no-repeat !important;
  width: 17px;
  height: 17px;
  background-size: contain !important;
  display: inline-block;
  position: relative;
  top: 6px;
}
.shape_model {
  padding-left: 145px;
  text-align: left;
  display: inline-block;
}
.brd_none {
  border: none !important;
}
.text_background {
  background-image: url("./assets/images/gr_shape.png") !important;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  height: 60px;
  background-position: center;
  color: #fff;
  font-size: 14px !important;
  padding-top: 11px;
  margin-top: 25px;
}
.green-text {
  color: #00cf76 !important;
}
.green-gradent {
  background: #00cf764a;
}
.gray-gradient {
  background-color: #464646c9 !important;
}
.gray-border {
  border: 1px solid #464646c9 !important;
}
.gradent-black {
  background: #0000008c !important;
}
.green-ground {
  background: #00cf76;
}
.text-red {
  color: #d61a1a !important;
}
.color-00 {
  color: #000;
}
.color-23 {
  color: #232323;
}
.color-97 {
  color: #979797;
}
.color-d6 {
  color: #d6d6d6;
}
.color-wh-8 {
  color: rgb(255, 255, 255, 0.8);
}
.eight {
  font-size: 8px;
}
.ten {
  font-size: 10px;
}
.one-two {
  font-size: 0.75rem;
}
.one-four {
  font-size: 0.875rem;
}
.one-five {
  font-size: 0.938rem;
}
.one-six {
  font-size: 1rem;
}
.one-eight {
  font-size: 1.125rem;
}
.two-zero {
  font-size: 1.25rem;
}
.two-five {
  font-size: 1.563rem;
}
.three-zero {
  font-size: 30px;
}
.four-five {
  font-size: 45px;
}
.bold-45 {
  font-size: 45px;
  font-weight: 600;
}
.bold-16 {
  font-size: 0.75rem;
  font-weight: 400;
}
.bold_35 {
  font-size: 35px;
  font-weight: 400;
}
.bold-18 {
  font-size: 0.938rem;
  font-weight: 700;
}
.bold-17 {
  font-size: 1.063rem;
  font-weight: 700;
}
.bold-14 {
  font-size: 14px !important;
  font-weight: 700;
}
.bold-15 {
  font-size: 0.938rem;
  font-weight: 700;
}
.bold-19 {
  font-size: 1.063rem;
  font-weight: 700;
}
.regular-45 {
  font-size: 45px;
  font-weight: 500;
}
.regular-18 {
  font-size: 0.938rem;
  font-weight: 500;
}
.regular-15 {
  font-size: 0.938rem;
  font-weight: 500;
}
.regular-16 {
  font-size: 1rem;
  font-weight: 500;
}
.regular-10 {
  font-size: 10px;
  font-weight: 500;
}
.regular-13 {
  font-size: 0.813rem;
  font-weight: 500;
}
.regular-12 {
  font-size: 0.75rem;
  font-weight: 500;
}
.regular-14 {
  font-size: 12px !important;
  font-weight: 500;
}
.min-radius-7 {
  border-radius: 7px;
}
.weight-700 {
  font-weight: 700;
}
.ih {
  margin-left: 250px !important;
}
.card-r-h {
  height: 475px;
}
.card-l-h {
  height: 506px;
}
.relativec {
  position: relative;
}
.pop-border {
  border: 1px solid #c9c9c9 !important;
  height: 45px;
}
.business_let {
  font-size: 1.125rem;
  font-weight: 700;
  color: #979797;
}
.business_let_para {
  font-size: 0.75rem;
  font-weight: 400;
}
.lop {
  color: white !important;
  outline: none !important;
  text-decoration: none !important;
  font-size: 0.875rem;
}
.lop:hover {
  opacity: 0.8;
}
.pnsr {
  position: relative;
  width: 244px;
  top: 22px;
  height: 50px;
}
.cnsr {
  position: absolute;
}
.mh_20 {
  min-height: 185px;
}
.mh_25 {
  min-height: 244px;
}
.about_ul ul li {
  opacity: 0.7;
  line-height: 20px;
  font-size: 0.813rem;
  margin-bottom: 10px;
}
.max_ul li a {
  color: #fff;
  margin-right: 20px !important;
  font-size: 0.938rem;
}
.bold-22 {
  font-family: helvetica;
  font-size: 1.375rem;
  font-weight: 600;
  color: #002d2f;
  line-height: 26px;
}
.btn_menus {
  background: none;
  border-radius: 5px;
  padding: 0px 10px;
  font-size: 0.875rem;
}
.btn_18 {
  font-size: 1.125rem;
  vertical-align: text-bottom;
}
.log-input {
  border: none !important;
  background-color: #f5f5f5 !important;
  padding-left: 40px !important;
  height: 38px !important;
  font-size: 12px !important;
  color: #979797 !important;
}
.search-dash:focus {
  border: 1px solid #e0e0e0 !important;
  outline: none;
}
.log-input:focus,
.btn-login:focus,
.account-input:focus,
.btn-folio-create:focus,
.btn-later:focus,
.cr-portfolio:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.log-input::placeholder {
  color: #979797 !important;
  font-size: 12px !important;
  font-family: helvetica;
}
.gr-pd {
  padding: 5px 0px;
}
.btn-login:hover {
  height: 38px;
}
.profile_tabs a {
  width: 50%;
  background: #fff;
  text-align: center;
  color: #000;
  border: none !important;
}
.profile_tabs a.active {
  background-color: #00cf76 !important;
  color: #fff !important;
}
.btn-login {
  background-color: #00cf76 !important;
  color: #ffff !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 38px;
  font-family: helvetica;
}
.contact_i {
  color: #00cf76;
  font-size: 39px;
  margin: 10px 0px;
}
.img-small {
  width: 24%;
  margin-top: -32px;
}
.contact_area {
  border: none !important;
  background-color: #848a91ba !important;
  padding-left: 40px !important;
  height: 100px !important;
  font-size: 16px !important;
  color: #fff !important;
  margin-bottom: 20px;
}
.contact_input {
  border: none !important;
  background-color: #848a91ba !important;
  padding-left: 40px !important;
  height: 45px !important;
  font-size: 16px !important;
  color: #fff !important;
  margin-bottom: 20px;
}
.contact_input::placeholder,
.contact_area::placeholder {
  color: #b1b1b1;
  opacity: 100%;
}
.meter_style {
  display: block;
  width: 70px;
  height: 38px;
  background-color: #ccc;
  border-radius: 80% 80% 12% 12% / 80% 80% 40% 40%;
  margin: auto;
  text-align: center;
  left: 93px;
  top: 82px;
  position: absolute;
}
.ReactSpeedometer-input {
  max-height: 24px;
  text-align: center;
  width: 75%;
  margin-top: 9px !important;
  background: #00cf76;
  border: 2px solid #000;
  border-radius: 100% 100% 17% 17% / 80% 80% 40% 40%;
  font-size: 13px;
  font-weight: bold;
}
.input-relative {
  position: relative;
}
.vh_100 {
  height: 84vh;
}
.pointer {
  cursor: pointer;
}
.same-icons {
  position: absolute;
  top: 12px;
  left: 10px;
}
.v_b {
  vertical-align: text-bottom;
}
.same-icons-rit {
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}
.search-dash {
  height: 45px;
  color: #fff;
  background: #0000008c;
  width: 100%;
  font-size: 0.875rem;
  border: 1px solid #e0e0e0 !important;
  border-radius: 3px;
  padding: 12px;
}
.dash-table-div {
  background: #00000096;
  margin-top: 14px;
  border-radius: 10px;
  color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}
.stockProfileCont {
  min-height: calc(100vh - 242px);
}
.emptyStockProfileCont {
  max-height: calc(100vh - 150px);
  min-height: calc(100vh - 150px);
}
.dash-table-div .table th,
.dash-table-div .table td {
  vertical-align: middle;
  border-top: 0px;
}
.search-dash::placeholder {
  color: #fff;
  font-size: 0.875rem;
}
.dash-table-div thead th {
  vertical-align: middle !important;
  border-bottom: 0px !important;
  font-size: 0.75rem;
  font-family: Helvetica-Bold !important;
  font-weight: 700;
  color: #fff;
}
.tracked-table-div thead th {
  font-size: 0.813rem;
  font-family: Helvetica-Regular !important;
  font-weight: 100;
  color: #fff;
}
.dash-table tr td {
  padding: 7px 0px 7px 0px !important;
}
.dash-table-div tr td:first-child {
  padding-left: 12px !important;
}
.dash-table-div tr:last-child td {
  border-bottom: 0px !important;
}
.dash-table-div thead tr {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px !important;
}
.dash-table-div tr td:last-child {
  border-bottom: 0px;
}
.dash-table-div tr td {
  vertical-align: middle !important;
  border-bottom: 1px solid #ccc !important;
  font-size: 0.875rem;
  font-family: helvetica;
  padding: 15px 8px;
  color: #fff;
}
.tracked-table-div tr td {
  font-size: 0.813rem;
}
.btn-edit {
  background: #00cf76;
  padding: 10px 15px;
  border-radius: 3px;
  color: #fff;
  font-size: 0.875rem;
  display: inline-block;
  margin-bottom: 5px;
}
.cpin:hover {
  color: black;
}
.cpin {
  color: black;
}
.btn-delete {
  background: #db2f2f;
  padding: 10px 8px;
  border-radius: 3px;
  color: #fff;
  font-size: 0.875rem;
  display: inline-block;
}
.btn-create {
  background: #00cf76;
  display: inline-block;
  color: #fff !important;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 0.875rem;
}
.btn-fil {
  border: 1px solid #00cf76;
  display: inline-block;
  color: #00cf76 !important;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 0.875rem;
}
.Portfolio-bk {
  background: #000;
  color: #fff;
  padding: 30px;
  border-radius: 5px;
}
.scrolltable thead,
.scrolltable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.scrolltable tbody {
  display: block;
  overflow: auto;
  height: calc(100vh - 293px);
}
ol li,
.entry-content ul li {
  margin-bottom: 10px;
}
.scroll {
  box-sizing: border-box;
}
.contact_100 {
  height: 82vh;
}
.contact_101 {
  height: calc(100vh - 116px);
}
.about_101 {
  min-height: calc(100vh - 116px);
}
.contact_100 {
  height: calc(100vh - 125px);
}
.contact_102 {
  height: 84vh;
}
.h_529 {
  height: 528px;
  overflow: hidden;
}
.bg-footer a {
  margin: 0px 10px;
}
.bg-footer a.active-menu:after {
  bottom: -4px;
}
.scroll {
  max-height: 500px;
  overflow-y: auto;
  margin: 0 auto;
  padding-right: 10px;
}
.scroll {
  scrollbar-width: 1px solid;
  scrollbar-color: #274392 orange;
}
.top-ul {
  list-style: none;
  padding-left: 0;
}
.top-ul li {
  display: inline-block;
  margin: 0px 25px;
  color: #fff;
  font-size: 0.813rem;
}
.marquee-container .overlay {
  --gradient-color: none !important;
}
.top-icon-end {
  background: #002d2f !important;
  color: #ff0000 !important;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 13px !important;
}
.top-icon-up {
  background: #002d2f !important;
  color: #00cf76 !important;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 13px !important;
}
.hide_w_menu {
  display: none;
}
.pop_usd {
  color: #002d2f;
  font-family: "Helvetica-Bold";
  font-size: 1rem;
  background: #f0f0f0;
  border: none;
  position: absolute;
  right: 2px;
  top: 2px;
  height: 41px;
  padding: 10px 28px;
}
.green_place input::placeholder {
  color: #00cf76;
}
.eye_right {
  position: absolute;
  right: 10px;
  top: 18px;
}
.btn_discard {
  background: #090808ad;
  font-size: 1rem;
  color: #fff;
  padding: 10px;
  font-weight: 700;
  border-radius: 3px;
  width: 100%;
  border: 1px solid #d61a1a;
}
.wh_ratio {
  background: #ffffff36;
  border-radius: 3px;
  padding: 4px;
}
.ratio_para {
  display: block;
  height: 380px;
  overflow-y: auto;
  padding-right: 15px;
}
.ratio_para h3 {
  font-size: 0.75rem;
  margin-top: 5px;
  background: #00cf7682;
  border-radius: 3px;
  padding: 4px;
}
.ratio_para p {
  font-size: 0.75rem;
  margin-bottom: 5px !important;
}
.table_harizontal tr td {
  min-width: 65px;
}
.cmb {
  color: white;
}
.profile {
  width: 45px;
  height: 45px !important;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #00cf76;
}
.dash-link li a {
  margin: 0px 12px;
}
.dash-link li a:hover,
.tab-ul li a:hover {
  text-decoration: none;
}
.btn-out .dropdown-toggle {
  background: transparent;
  border: navajowhite;
  text-align: left;
  line-height: 16px;
}
.btn-out .dropdown-toggle:hover {
  background: none;
}
.btn-out .dropdown-toggle:focus {
  border: none !important;
  background: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.bg-footer {
  background: #002d2f;
}
.top-ul li:last-child {
  margin-right: 0px !important;
}
.tab-ul {
  list-style: none;
  padding-left: 0px;
}
.tab-ul li {
  display: inline-block;
}
.inf {
  font-size: 1rem;
  margin-top: 20px;
  color: #f2e6e6;
}
.ball_size img {
  width: 110px;
  height: 110px;
  object-fit: contain;
}
.tab_right li a {
  color: #fff;
  margin-right: 15px !important;
}
.tab-ul li a {
  color: #fff;
  margin-right: 8px;
  font-size: 0.938rem;
}
.btn-buy {
  background: #00cf76;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
}
/*.btn-value{border: 2px solid #00CF76;background: #00CF76;color:#fff!important; padding: 10px 15px;box-shadow: 0px 0px 10px 0px #00CF76;border-radius: 5px;font-size: 0.813rem;}*/
.btn-value {
  position: relative;
  opacity: 1 !important;
  background: #00cf76;
  border: 2px solid #00cf76;
  color: #fff !important;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 0.813rem;
  cursor: pointer;
}
.btn {
  position: relative;
}
.btn-value:hover:before,
.btn:hover:before,
.btn-find:hover:before,
.red_change:hover:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  z-index: 1;
  background-color: black;
}
.btn-find {
  background: #002d2f;
  border: 2px solid #00cf76;
  color: #fff !important;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 0.813rem;
  cursor: pointer;
}
.btn-ignore {
  background: #d61a1a;
  color: #fff !important;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.938rem;
}
.selcet-port {
  background-color: #464646c9 !important;
  height: 45px !important;
  color: #fff !important;
}
.btn-dwn {
  background: #00cf76;
  color: #fff;
  padding: 10px 15px;
  display: inline-block;
  border-radius: 3px;
  width: 100%;
  text-align: center;
}
.btn-create:hover,
.btn-dwn:hover,
.btn-value:hover,
.btn-ignore:hover,
.btn-buy:hover,
.btn-folio:hover,
.btn-folio-create:hover,
.cr-portfolio:hover {
  color: #fff;
  opacity: 70%;
  text-decoration: none;
}
.padding-para p {
  padding: 4px 0px;
}
.list-hd {
  background: #d9d9d973;
  font-size: 0.938rem;
  text-align: center;
  font-weight: 700;
  width: 20%;
  padding: 12px;
  border-radius: 5px;
  color: #fff;
}
.w-list-h {
  max-height: 680px;
  height: 100%;
  overflow: hidden;
}
.scrolltable tbody tr:hover,
.scrolltable tbody tr.active {
  background: #bbffe226;
}
.grg-img {
  position: absolute;
  left: 40%;
  top: 10%;
  display: inline-block;
  width: 15%;
}
.pitch-para p {
  padding: 4px 0px;
}
.cam-icon {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  cursor: pointer;
}
.cam-text {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  cursor: pointer;
}
.upload-img {
  width: 135px;
  height: 135px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #00cf76;
  max-width: 135px;
  max-height: 135px;
}
.account-input {
  border: none !important;
  background-color: #848a91ba !important;
  padding-left: 40px !important;
  height: 45px !important;
  font-size: 16px !important;
  color: #fff !important;
}
.account-input::placeholder,
.table-input::placeholder {
  color: #fff !important;
}
.btn-account {
  background: #00cf76;
  border: none;
  font-size: 1rem;
  color: #fff;
  padding: 10px;
  font-weight: 700;
  border-radius: 3px;
  width: 100%;
}
.btn-dis {
  border: 1px solid #d61a1a;
  background: #0000008c;
  font-size: 1rem;
  color: #fff;
  padding: 10px;
  font-weight: 700;
  border-radius: 3px;
  width: 100%;
}
.btn-folio {
  background: #707070ed;
  color: #fff;
  padding: 10px 25px;
  display: inline-block;
  border-radius: 3px;
  text-align: center;
}
.btn-folio-create {
  font-weight: 700;
  border: 1px solid #00cf76;
  color: #00cf76 !important;
  padding: 10px 30px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
}
.btn-later {
  border: 1px solid #00cf76;
  color: #00cf76;
  padding: 6px 20px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
  font-weight: 600;
}
.btn-later:hover {
  color: #00cf76;
  opacity: 60%;
  text-decoration: none;
}
.no-boreder tr td {
  border-bottom: 0px !important ;
}
.table-input {
  border: 1px solid #e0e0e0 !important;
  background-color: #848a91ba !important;
  height: 45px !important;
  font-size: 16px !important;
  color: #fff !important;
}
.market-cd-h {
  height: 100%;
  max-height: 698px;
}
.market-cd-hs {
  height: 100%;
  max-height: 698px;
}
.spinner-border {
  width: 1rem !important;
  height: 1rem !important;
}
.login-img-top {
  height: 200px !important;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
.sign-img-top {
  height: 130px !important;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
.log-bg-radius {
  border-radius: 0px 0px 10px 10px;
}
.card-click:focus {
  border: 1px solid #00cf76;
  color: #00cf76;
}
.card-click {
  border: 2px solid #ddd;
  height: 67px;
}
.track_pitch_h {
  height: calc(100vh - 190px);
}
.track_table th,
.track_table tr {
  padding: 15px 8px;
}
.pop-clr-gr {
  background: #e1fff2;
  color: #86ccae;
  font-size: 0.875rem;
  padding: 4px 6px;
  border-radius: 5px;
}
.dash_pich {
  background: #00000096;
  border-radius: 10px;
  color: #fff;
}
.ball_pich img {
  width: 95px;
  height: 95px;
  object-fit: contain;
}
.pop-clr-act {
  background: #e1fff2;
  color: #86ccae;
  border: 1px solid #86ccae;
  white-space: nowrap;
  font-size: 0.875rem;
  padding: 4px 6px;
  border-radius: 5px;
}
.pop-clr-noact {
  background: #fff;
  white-space: nowrap;
  color: #232323;
  border: 1px solid #232323;
  display: inline-block;
  font-size: 0.875rem;
  padding: 4px 6px;
  border-radius: 5px;
}
.checkboxCont {
  color: #232323;
  position: relative;
}
.empty-po-h {
  padding-top: 100px;
}
.empty-h {
  height: 80vh;
}
.pop-clr-noact:hover {
  color: #232323;
}
.select-po:hover,
.pop-clr-act:hover {
  color: #00cf76;
}
.select-po:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.select-po::after {
  display: none;
}
.select-po {
  border: 1px solid #00cf76;
  color: #00cf76;
  padding: 4px 6px !important;
  font-size: 0.875rem;
}
.gradientlog:before {
  background-image: linear-gradient(
    to right,
    #64cfec96,
    #72d9df73,
    #8be0d270,
    #aae6c68a,
    #c8eac094
  );
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  position: absolute;
}
.form-group {
  margin-bottom: 0.5rem;
}
.outer_x {
  padding-right: 50px !important;
  overflow-x: hidden;
}
.regular_12_a2 {
  font-size: 0.754rem;
  font-family: "Helvetica-Regular";
  color: #a29b9b;
}
.headerlink a {
  font-family: helvetica;
  font-size: 0.75rem;
  color: #fff !important;
  vertical-align: middle !important;
  line-height: 36px;
  margin: 0px 10px;
  padding: 0;
}
.btn_delete {
  background: #e50e0e;
  border: none;
  font-size: 1rem;
  color: #fff;
  padding: 10px;
  font-weight: 700;
  border-radius: 3px;
  width: 100%;
}
.hight_list {
  max-height: calc(100vh - 280px);
  min-height: calc(100vh - 270px);
}
.w-90 {
  width: 90%;
}
.navbar-light .navbar-toggler-icon {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}
.red_change {
  background: #ec3b13;
  color: #fff !important;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 0.813rem;
  display: inline-block;
  cursor: pointer;
}
.stock-p-h {
  height: 370px;
  overflow-y: auto;
}
.para-c-h {
  height: 100%;
  min-height: 445px;
  overflow-y: auto;
}
.head-folio {
  text-align: center;
  padding: 20px;
  border-radius: 30px;
  margin-top: -47px !important;
  background-image: linear-gradient(
    to top,
    #00cf76f7,
    #00cf76,
    #00cf76,
    #01d077,
    #7bedbc
  );
  box-shadow: 1px 6px 22px 1px rgb(0 0 0);
}
.ball-grad img {
  width: 55%;
  margin-bottom: 10px;
}
.scroll-ball {
  overflow-x: hidden;
  padding-right: 10px;
  overflow-y: auto;
}
.clrbut:focus {
  background: transparent;
  border: none;
  box-shadow: none;
}
.scroll-ball::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
  border-radius: 30px;
}
.scroll-ball::-webkit-scrollbar {
  background-color: #000;
}
.scroll-ball::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #bbffe2;
}
.scroll-ball::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background-color: #707070;
}

.market-scroll::-webkit-scrollbar,
.table_harizontal::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #979797;
}
.market-scroll::-webkit-scrollbar,
.table_harizontal::-webkit-scrollbar {
  background-color: #979797;
  border-radius: 10px;
}
.market-scroll::-webkit-scrollbar-thumb,
.table_harizontal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #00cf76;
}
.market-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #dfdfdf;
}
.ball-grad {
  background: rgb(0, 0, 0, 0.3);
  border-radius: 18px;
  text-align: center;
  padding: 11px;
  margin-bottom: 20px;
  border: 3px solid rgb(0 0 0 / 0%);
}
.ball-grad:hover {
  border: 3px solid #fff;
  border-radius: 18px;
}
.logo {
  width: 42px;
}
.cr-portfolio {
  background: #00cf76;
  width: 100%;
  display: inline-block;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  padding: 8px;
}
.table-value {
  background: #00000096;
  margin-top: 14px;
  border-radius: 10px;
  color: #fff;
}
.filter-i {
  position: absolute;
  right: 40px;
  cursor: pointer;
  top: 14px;
  color: #00cf76 !important;
}
.table-right-input::placeholder {
  color: #fff;
}
.table-right-input {
  background-color: #8c8c8cb5 !important;
  height: 40px !important;
  font-size: 13px !important;
  color: #fff !important;
}
.valuation_txt {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 15px;
}
.valuation_txt_gr {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #00cf76;
}
.btn_selfis {
  background: #00cf76;
  padding: 0px 37px;
  border-radius: 5px;
  font-size: 0.75rem;
  color: #fff !important;
  vertical-align: middle !important;
  line-height: 36px;
  margin: 0px 3px;
  border: 1px solid #00cf76;
}

.regular_28_r {
  font-size: 28px;
  font-weight: 500;
}
.ioh {
  position: absolute;
}
.valuvation_label {
  font-size: 10px;
  background: #ffffff8f;
  padding: 2px 5px;
  border-radius: 3px;
  margin-left: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.tab-ul .active {
  color: #00cf76;
  border-bottom: 4px solid #00cf76;
  padding-bottom: 4px;
  border-radius: 4px;
  cursor: pointer;
}
.tab-ul a {
  cursor: pointer;
}
.model-table tr td {
  font-size: 0.75rem;
  font-weight: 500;
  padding-bottom: 15px;
  padding-right: 10px;
}
.btn_selfi {
  background: #00cf76;
  padding: 0px 7px;
  border-radius: 5px;
}
.btn_selfi_grad {
  border-radius: 5px;
  padding: 0px 8px;
  background-image: linear-gradient(
    to right,
    #4b7fdd,
    #009cef,
    #00b3de,
    #00c4b1,
    #00cf76
  );
}
.text_25_76 {
  color: #00cf76;
  font-size: 1.563rem;
}
.top-icon-end-gree {
  background: #002d2f !important;
  color: #00cf76 !important;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 13px !important;
}
.news_hight {
  max-height: 386px;
  overflow-x: hidden;
}
.news_img {
  width: 100%;
  height: 100%;
  min-height: 140px;
  object-fit: cover;
  border-radius: 7px 0px 0px 7px;
}
.news_page {
  background-color: #002d2f;
  margin-top: 10px;
  border-radius: 7px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  min-height: 140px;
}
.news_page:hover {
  transform: scale(1.03);
  transition: all 0.5s ease-in-out;
}
.dash_top_btn {
  background: #002d2f;
  color: #00cf76;
  font-size: 0.875rem;
  font-family: "Helvetica-Regular";
  border: 2px solid;
  padding: 6px 20px;
  border-radius: 5px;
}
.btn_blog {
  color: #002d2f;
  border: 2px solid #002d2f;
  background: #00cf76;
  font-size: 0.875rem;
  font-family: "Helvetica-Regular";
  padding: 4px 18px;
}
.form-control.bg_f0::placeholder {
  color: #a29b9b !important;
}
.bg_f0 {
  background: #f0f0f0;
}
.regular_11 {
  font-size: 0.688rem;
  font-family: "Helvetica-Regular";
}
.regular_15_70 {
  color: #707070;
  font-size: 0.938rem;
  font-family: "Helvetica-Regular";
}
.regular_12 {
  font-size: 0.75rem;
  font-family: "Helvetica-Regular";
}
.two_zero {
  font-size: 1.25rem;
}
.regular_15 {
  font-size: 0.938rem;
  font-family: "Helvetica-Regular";
}
.regular_14 {
  font-size: 0.875rem;
  font-family: "Helvetica-Regular";
}
.regular_14_ff {
  font-size: 0.875rem;
  font-family: "Helvetica-Regular";
  color: #fff;
}
.regular_15_76 {
  font-family: "Helvetica-regular" !important;
  font-size: 0.938rem;
  color: #00cf76;
}
.regular_15_a2 {
  font-size: 0.938rem;
  font-family: "Helvetica-Regular";
  color: #a29b9b;
}
.regular_12_a2 {
  font-size: 0.75rem;
  font-family: "Helvetica-Regular";
  color: #a29b9b;
}
.regular_12_a3 {
  font-size: 1rem;
  font-family: "Helvetica-Regular";
  color: #a29b9b;
}
.regular_14_a2 {
  font-size: 0.875rem;
  font-family: "Helvetica-Regular";
  color: #a29b9b;
}
.regular_19 {
  font-size: 1.118rem;
  font-family: "Helvetica-Regular";
}
.regular_35 {
  font-size: 35px;
  font-family: "Helvetica-Regular";
}
.regular_21 {
  font-size: 1.313rem;
  font-family: "Helvetica-Regular";
}
.regular_15_97 {
  font-size: 0.938rem;
  color: #979797;
}
.regular_13_ff {
  font-family: "Helvetica-Regular" !important;
  font-size: 0.813rem;
  color: #fff;
}
.regular_13 {
  font-family: "Helvetica-Regular" !important;
  font-size: 0.813rem;
}
.regular_15_2f {
  font-size: 0.938rem;
  color: #002d2f;
}
.regular_30 {
  font-size: 38px;
  font-family: "Helvetica-Regular";
}
.end-img img {
  width: 100%;
  height: 132px;
  object-fit: contain;
}
.show_text {
  color: #00cf76;
  font-size: 0.813rem;
  font-family: "Helvetica-Bold" !important;
  text-decoration: underline;
}
.bold_14_ff {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.875rem;
  color: #fff;
}
.bold_12_ff {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.75rem;
  color: #fff;
}
.bold_15_23 {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.938rem;
  color: #232323;
}
.bold_15_d6 {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.938rem;
  color: #d61a1a;
}
.bold_12 {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.75rem;
}
.bold_14_76 {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.875rem;
  color: #00cf76;
}
.bold_14 {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.875rem;
}
.bold_15 {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.938rem;
}
.bold_18 {
  font-family: "Helvetica-Bold" !important;
  font-size: 1.125rem;
}
.bold_20 {
  font-family: "Helvetica-Bold" !important;
  font-size: 1.25rem;
}
.bold_15_2f {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.938rem;
  color: #002d2f;
}
.bold_15_76 {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.938rem;
  color: #00cf76;
}
.bold_12_76 {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.75rem;
  color: #00cf76;
}
.bold_30_76 {
  font-family: "Helvetica-Bold" !important;
  font-size: 30px;
  color: #00cf76;
}
.bold_13_76 {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.813rem;
  color: #00cf76;
}
.bold_15_97 {
  font-family: "Helvetica-Bold" !important;
  font-size: 0.938rem;
  color: #979797;
}
.bold_20_76 {
  font-family: "Helvetica-Bold" !important;
  font-size: 1.25rem;
  color: #00cf76;
}
.bold_26_76 {
  font-family: "Helvetica-Bold" !important;
  font-size: 26px;
  color: #00cf76;
}
.height_340 {
  overflow-y: auto;
  height: 340px;
  padding-right: 15px;
}
.form_border_hgt {
  border: 2px solid #28a745 !important;
  height: 45px;
}
.ai_delete {
  background: #d61a1a;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
}
.track_table th,
.track_table td {
  text-align: left !important;
}
.track_table th:first-child {
  border-radius: 5px 0px 0px 5px !important;
}
.track_table th:last-child {
  border-radius: 0px 5px 5px 0px !important;
}
.popover_slider {
  margin: 16px 0;
  position: relative;
  background: #979797;
  touch-action: none;
  height: 6px;
  border-radius: 10px;
}
.custom_slider .regular_12 {
  min-height: 124px;
}
.custom_slider .slick-prev,
.custom_slider .slick-next {
  top: 40%;
}
.custom_slider .slick-prev:before,
.custom_slider .slick-next:before {
  font-size: 30px;
  color: #000000a3;
}
.custom_slider .slick-prev {
  left: -40px;
}
.small_table {
  margin-bottom: 0;
  margin-top: 2px;
}
.small_table tr:first-child td {
  font-size: 12px !important;
  font-weight: 600;
  color: #00cf76;
}
.small_table td {
  padding: 0px 5px !important;
  vertical-align: top;
  color: #00cf76;
  font-size: 10px !important;
  border: none;
}
.small_table tr td {
  color: #00cf76;
}
.table_harizontal {
  table-layout: fixed;
  width: 100%;
  margin-left: 0px;
}
.table_harizontal tr td,
.table_harizontal th {
  vertical-align: top;
  border: none !important;
  border-bottom: none !important;
  width: 100px;
  font-size: 13px !important;
  padding: 1px 5px !important;
}
.table_harizontal th {
  position: absolute;
  left: 0;
  width: 288px;
}

.tabel_in {
  background: #ffffff7a;
  color: #fff;
  padding: 3px 4px;
  border-radius: 4px;
}
.outer .table_harizontal tr td,
.outer .table_harizontal th {
  padding: 2px 5px !important;
  font-family: "Helvetica-Regular" !important;
}
.table_harizontal tbody {
  display: block;
  overflow-x: auto;
  padding-left: 3px;
}
.outer {
  display: block;
  overflow-y: auto;
  padding-right: 150px;
  position: relative;
}
.inner {
  width: 63%;
  margin-left: 285px;
}
.top_15 {
  margin-top: -15px;
}
.opacity_0 {
  opacity: 0;
}
.profile_slide {
  margin: auto;
  width: 32% !important;
}
.custom_slider .slick-list {
  height: 370px;
}
.custom_slider .slick-slide {
  background: #00000096;
  padding: 20px 16px 30px;
  border-radius: 8px;
  text-align: center;
  margin: 0px 5px;
  height: 500px;
}
.inline_field {
  display: inline-block !important;
  width: 75% !important;
}
.auto_input {
  width: auto !important;
  display: inline-block !important;
  padding-right: 0px;
}
.bg_share {
  background: #002d2fe8;
  padding: 5px;
  border-radius: 6px;
}
.show_shave {
  background: #ececec;
  border-radius: 5px;
  padding: 10px;
}
.popover_slider .rangeslider__handle:after {
  content: " ";
  position: absolute;
  width: 15px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: transparent !important;
  box-shadow: none;
}
.top_50 {
  margin-top: -50px;
}
.popover_slider .rangeslider__handle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.popover_slider .rangeslider__handle {
  background: #00cf76;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgb(0 0 0 / 40%), 0 -1px 3px rgb(0 0 0 / 40%);
}
.btn_save {
  border: 1px solid #00cf76;
  color: #00cf76 !important;
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  font-size: 0.875rem;
}
.black_grad {
  background: #00000096;
  margin-top: 14px;
  color: #fff;
}
.user_tab {
  background: #00000096;
  border-bottom: none;
}
.user_tab a:hover {
  border: none;
}
.user_tab a {
  background: #000;
  border-radius: 5px !important;
  margin: 2px !important;
  font-size: 0.938rem;
  color: #fff;
  text-transform: none;
  border: none !important;
  width: 19.7%;
  text-align: center;
}
.user_tab a.active {
  background-color: #00cf76 !important;
  font-family: "Helvetica-Bold" !important;
  color: #000 !important;
  margin: 2px !important;
}
.grad_table {
  border-collapse: separate;
  border-spacing: 0 2px;
}
.grad_table tr th {
  background: #00000096;
  border-bottom: none;
  font-size: 0.813rem;
  padding: 6px !important;
  font-family: "Helvetica-Bold" !important;
  border-top: 0;
  vertical-align: middle;
}
.grad_table tr td {
  background: #000000ba;
  border-top: none;
  font-size: 0.813rem;
  vertical-align: middle;
  padding: 6px !important;
}
.grad_table tr td:nth-child(odd):after {
  background: #74727291;
  display: inline-block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.ball_grad {
  background: #d0c0c03d;
  padding: 5px;
  border-radius: 5px;
}
.psre {
  position: relative;
  display: inline-block;
}
.psren {
  position: absolute;
  top: -1px;
  right: -10px;
}
.psreps {
  position: absolute;
  top: -1px;
  right: -10px;
}
.psrepb {
  position: absolute;
  top: -1px;
  right: -10px;
}
.psreeve {
  position: absolute;
  top: -1px;
  right: -10px;
}
.ins {
  background: #313e42;
  opacity: 1 !important;
  padding: 10px 5px;
  min-width: 300px;
}
.insp {
  background: #313e42;
  opacity: 1 !important;
  padding: 10px 5px;
  min-width: 100px;
}
.bs-popover-right > .arrow:after {
  border-right-color: #313e42;
}
.rbs {
  opacity: 0 !important;
}
.vs_top {
  padding-top: 140px;
}
.btn_pitch {
  color: #fff !important;
  background-color: #002d2f;
  border: 2px solid #28a745;
  font-size: 0.813rem;
  font-family: "Helvetica-Regular";
  padding: 10px 15px;
  min-width: 175px;
}
.share_btn {
  color: #28a745 !important;
  background-color: #002d2f;
  border: 2px solid #002d2f;
  font-size: 0.938rem;
  font-family: "Helvetica-Bold";
  padding: 10px 13px;
}
.invest_btn {
  color: #fff !important;
  background-color: #00cf76;
  border: 2px solid #00cf76;
  font-size: 0.813rem;
  font-family: "Helvetica-Regular";
  padding: 8px 15px;
}
.remove_btn {
  color: #fff;
  background-color: #d61a1a;
  border: 2px solid #d61a1a;
  font-size: 0.813rem;
  font-family: "Helvetica-Regular";
  padding: 8px 15px;
}
.color_d6 {
  color: #d61a1a;
}
.radio_box {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
  padding: 5px 8px;
  border-radius: 5px;
}
.custom_pop_wid .modal-dialog {
  max-width: 630px !important;
}
.small_table_in input {
  height: 25px !important;
  font-size: 0.75rem !important;
  color: #fff !important;
}
.small_table_in tr td {
  font-size: 0.75rem;
  padding-bottom: 10px;
  padding-right: 5px;
}
.small_table_in p {
  font-size: 0.75rem;
}
.sugg {
  color: #00cf76;
  margin-bottom: 0px;
  font-size: 0.75rem;
}
.form-check-input {
  right: 0;
  top: -6px;
}
.form-check-input ~ .checkmark {
  color: #aaa;
}
.form-check-input:checked ~ .checkmark {
  color: #00cf76;
}

.form-check-input ~ .checktitle {
  color: #aaa;
  padding: 10px;
  display: inline-block;
  font-weight: bold;
}
.form-check-input:checked ~ .checktitle {
  color: #00cf76;
}

.form-check-input ~ .checkborder {
  position: absolute;
  width: 93px;
  height: 67px;
  left: 0;
  top: 0;
  border: 2px solid #ccc;
  border-radius: 5px;
}
.form-check-input:checked ~ .checkborder {
  position: absolute;
  width: 93px;
  height: 67px;
  left: 0;
  top: 0;
  border: 2px solid #00cf76;
  border-radius: 5px;
}

.form-check {
  padding-left: 8px;
}
.tip_tool button {
  padding: 0px !important;
  font-size: 0.875rem;
}
.pointer tr {
  cursor: pointer;
}
.accord_position {
  position: relative;
}
.bg_d2f {
  background: #002d2f;
  border-radius: 5px;
  position: absolute;
  width: 290px;
  z-index: 9;
}
.bg_d2f .nav-link,
.bg_d2f button {
  color: #00cf76 !important;
  font-size: 0.9rem;
  padding: 3px 12px !important;
}
.bg_d2f .nav-link:hover,
.bg_d2f .nav-link:focus {
  color: #00cf76;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}
.pl_mi {
  color: #fff;
  background: #00cf76;
  border-radius: 50%;
  padding: 2px;
  margin-left: 5px;
  font-size: 0.938rem;
}
.center_ul {
  list-style: none;
  padding-left: 12px;
}
.center_ul li {
  font-size: 0.9rem;
  line-height: 24px;
  color: #fff;
}
.accordion_ul_scroll {
  display: block;
  overflow: auto;
  max-height: calc(100vh - 280px);
  padding-right: 20px;
}
.clrbut,
.img-text {
  font-size: 0.875rem;
}
.accordion_ul_scroll::-webkit-scrollbar,
.table_harizontal::-webkit-scrollbar {
  width: 6px;
  background-color: #aaa;
}
.accordion_ul_scroll::-webkit-scrollbar,
.table_harizontal::-webkit-scrollbar {
  background-color: #979797;
  border-radius: 10px;
}
.accordion_ul_scroll::-webkit-scrollbar-thumb,
.table_harizontal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #00cf76;
}
.accordion_ul_scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #dfdfdf;
}
.sympol {
  position: relative;
}

.sympol:after {
  flex-shrink: 0;
  width: 15px;
  height: 15px;
  content: "";
  background-image: url("./assets/images/plus.png");
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  margin-left: 10px;
  margin-top: 2px;
}
.log_top_btn {
  background: transparent;
  color: #00cf76;
  font-size: 0.75rem;
  font-family: "Helvetica-Regular";
  border: 2px solid;
  padding: 8px 20px;
  border-radius: 5px;
}
.sympol.open-class:after {
  background-image: url("./assets/images/minus.png") !important;
}
.sympol.closed-class:after {
  background-image: url("./assets/images/plus.png") !important;
}
.container_radio {
  display: block;
  position: relative;
  padding: 8px 0px;
  cursor: pointer;
  font-size: 0.813rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cursor-pointer {
  cursor: pointer;
}
/* Hide the browser's default radio button */
.container_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 4px;
  right: 4px;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid;
}

/* On mouse-over, add a grey background color */
.container_radio:hover input ~ .checkmark {
  background-color: #fff !important;
}

/* When the radio button is checked, add a blue background */
.container_radio input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container_radio .checkmark:after {
  top: 1px;
  left: 1px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #00cf76;
}

/*{.form-check input[type=radio] {
  position: absolute;
opacity:0;
}
.form-check .form-check-label::before {
  content: "";
    background: transparent;
    border-radius: 100%;
    border: 2px solid #b4b4b4;
    display: inline-block;
    width: 15px;
    height: 15px;
    position: relative;
    top: 0px;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
    left: 75px;
}
.form-check input[type=radio]:checked + .form-check:before {
  background-color: #00CF76;
  box-shadow: inset 0 0 0 2px #f4f4f4;
}


.form-check input[type=radio]:disabled + .form-check input:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
}
*/

.form-control {
  border: 1px solid transparent;
}
.form-group {
  position: relative;
}
.errorInput {
  border: 1px solid red !important;
}
.errorCont {
  position: absolute;
  background: #f54949;
  color: #fff;
  top: -18px;
  font-size: 0.688rem;
  letter-spacing: 2px;
  padding: 1px 7px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #00cf76 !important;
}
.sloader {
  position: fixed;
  left: 0;
  top: 50%;
  right: 0;
  z-index: 9;
  margin: auto;
  width: 100px;
}
a.active-menu {
  position: relative;
  display: inline-block;
}
a.active-menu:after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: #00cf76;
  bottom: -8px;
}
.clrbut {
  background: none;
  border: none;
}
.clrbut:hover {
  background: none;
}
.dropdownBox {
  background: #fff;
  position: absolute;
  width: 100%;
  z-index: 9;
}
input[type="checkbox"] ~ .pop-clr-noact {
  border: 1px solid #000;
  width: 100%;
  position: absolute;
  left: 0;
}
.k {
  cursor: pointer;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.clrtol {
  color: #00cf76;
  height: 12px;
}
.bdr {
  background-color: #141a1b;
}
.valuationCheck {
  display: inline-block;
  width: 93px;
  margin-right: 7px;
  height: 67px;
  position: relative;
  vertical-align: top;
}
.content {
  justify-content: center;
}
.dropdown-menu {
  padding: 0;
  border-radius: 5px;
}
.table-left-input {
  background: none !important;
  padding-left: 0px !important;
}
.noicon:after {
  display: none !important;
}
.himage {
  height: 91px;
  margin-top: 16px;
}

#_capacitor-camera-input {
  display: none;
}
.editBtn {
  font-size: 0.813rem;
  padding: 2px 15px;
  line-height: 18px;
}
.valueText {
  font-size: 0.75rem;
  font-weight: 500;
}
.tyu {
  height: 158px;
  object-fit: contain;
}

.moats-img {
  cursor: pointer;
}
#popover-positioned-top {
  margin-top: 350px;
  display: inline-block;
  margin-left: -203px;
  min-height: 130px;
  width: 300px;
  border-radius: 8px;
}
.moats-table {
  height: 196px;
}
.moats-header {
  font-weight: bolder;
}
.img-text {
  text-align: center;
  margin-top: 5px;
}
.img-text-2 {
  text-align: center;
  margin-top: 5px;
  font-weight: bolder;
}
.moats-text {
  font-weight: lighter;
  font-weight: lighter;
  border: 1px solid black;
  background-color: #ded7d7;
  color: #282828;
  padding: 1px 10px;
  display: inline-block;
  border-radius: 3px;
}
.table_value tr td {
  font-size: 0.875rem;
  font-weight: 600;
  vertical-align: middle;
}
.table_value {
  height: 130px;
  overflow-y: auto;
}
.text-financial {
  font-size: small;
  height: 50px;
  width: 280px;
  position: absolute;
  left: 30px;
  top: 39px;
  text-align: start;
}

.mobile_none {
  display: none;
}
.btn_Toast {
  background-color: transparent;
  color: white;
  border: none;
}
.inputAsDisply {
  border: none;
  outline: none;
  box-shadow: none;
  cursor: auto;
  caret-color: transparent;
}
.pop-up {
  text-align: center;
  font-size: x-large;
}

.close-pop {
  text-align: right !important;
  cursor: pointer;
}
.Spinner {
  position: relative;
  text-align: center !important;
  top: 250px;
}
.value_bus {
  text-align: center;
  font-weight: bolder;
  color: red;
}
.my_acc {
  font-size: 1.55rem;
}
.change-img {
  width: 135px;
  height: 135px;
  position: relative;
  margin: auto;
}
.link_signup {
  margin-top: 10px;
}
.text-info {
  color: #00cf76 !important;
  font-size: 30px;
  margin-bottom: 15px;
  margin-top: 25px;
}
.Number_t {
  width: 25px;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}
.text_le {
  width: calc(100% - 30px);
  display: inline-block;
}
.text_smt {
  width: calc(100% - 34px);
  display: inline-block;
  vertical-align: top;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .btn-edit {
    font-size: 0.875rem;
    width: 100px;
    display: inline-block;
    margin-bottom: 5px;
    text-align: center;
  }
  .text-info {
    font-size: 18px;
    margin-left: 0;
  }
  .shape_model {
    padding-left: 75px;
  }
  .text_background {
    padding-top: 8px;
  }
  .hide_w_menu {
    display: block;
  }
  .hide_m_menu {
    display: none;
  }
  .mobile_logout {
    text-align: left;
    padding-left: 38px;
  }
  .table {
    width: 600px;
  }
  .btn-find {
    margin-top: 20px;
  }
  .btn-delete {
    font-size: 0.875rem;
    display: inline-block;
    font-size: 0.875rem;
    width: 100px;
    text-align: center;
  }
  .btn-create {
    width: 100%;
    text-align: center;
  }
  .headerlink a {
    padding-left: 0px;
  }
  .navbar-nav a {
    text-align: left;
  }
  .mobi-block {
    display: block;
  }
  .mobi-in-block {
    display: inline-block;
  }
  .valuationCheck {
    margin-bottom: 10px;
  }
  .valuationCont {
    text-align: center;
  }
  .btn-folio,
  .btn-folio-create,
  .btn-folio {
    width: 100%;
  }
  .btn-dwn {
    width: 100%;
    margin-top: 15px;
  }
  .list-hd {
    width: 45%;
    text-align: center;
  }
  .para-c-h,
  .stock-p-h {
    height: 100%;
    min-height: 100%;
  }
}
.clr {
  color: white;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .table {
    width: 790px;
  }
  .headerlink a {
    padding-left: 0px;
  }
  .navbar-nav a {
    text-align: left;
  }
  .clrbut,
  .img-text {
    font-size: 1rem;
  }
  .upload-img {
    max-width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #00cf76;
  }
  .footer-i {
    padding-right: 3px !important;
  }
  .tab-top {
    padding-top: 60px;
  }
  .login-back-tab,
  .gradientlog-tap {
    height: 100vh;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1366px) {
  .headerlink a {
    margin: 0px 10px;
  }
}
